import moment from "moment";
import React, { useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { ExcelIcon, ExportIcon, PDFIcon } from "../icons";
import { useDownloadExcel } from "../useDownloadExcel";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import { branch_id } from "../../utils";
import currency from "currency.js";

export const EmployeeExports = ({ Employees, showSalary }) => {
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel] = useDownloadExcel(excelData, CSVLinkRef);

  const onDownloadExcelData = async () => {
    if (!Employees || !Employees?.length > 0) {
      return toast.error("No Employee to download", { position: "top-right" });
    }

    let exData = Employees.filter(
      (emp) => !emp.hasResigned && !emp.isTerminated
    )?.map((employee, index) => [
      index + 1,
      `${
        employee.personalInfo?.name?.first +
        " " +
        employee.personalInfo?.name?.last
      }`,
      showSalary
        ? currency(employee.jobInfo?.salary || 0, {
            symbol: "",
          }).format()
        : "",
      employee.jobInfo?.employeeId,
      employee.jobInfo?.grade?.name,
      employee.personalInfo?.email,
      employee.personalInfo?.phone?.home,
      employee.jobInfo?.joinDate,
      employee.jobInfo?.startDate,
    ]);

    const normalHead = [
      "No",
      "Name",
      showSalary ? "Salary" : "",
      "EmployeeId",
      "Job Grade",
      "Email",
      "Mobile",
      "Join Date",
      "Start Date",
    ];

    const date = `Date Prepared: ${moment(new Date()).format(
      "MMMM DD yyyy k:mm:ss z"
    )}`;

    exData = [
      // [company],
      ["Employee Details"],
      [date],
      [""],
      normalHead,
      ...exData,
      [""],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <div>
      <CSVLink
        className="btn print d-none"
        filename={`Employee Details(${moment(new Date()).format(
          "DD-MMM-yyyy hh:mm:ss a"
        )}).csv`}
        data={excelData}
        ref={CSVLinkRef}
      />

      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="print"
          disabled={isfetchingExcel}
          bsPrefix=""
        >
          <span className="mx-2">Export</span>
          <ExportIcon color="#008000" />
        </Dropdown.Toggle>
        <Dropdown.Menu
          popperConfig={{
            strategy: "fixed",
          }}
          renderOnMount
          className="text-center"
        >
          <Dropdown.Item
            as="div"
            onClick={onDownloadExcelData}
            className="p-cursor"
          >
            Excel <ExcelIcon color="#008000" />
          </Dropdown.Item>
          <Dropdown.Item
            href={`${URLS.backendEmployees}/PDF/${branch_id}?imageUrl=${URLS.backendStatic}&showSalary=${showSalary}`}
            target="blank"
            className="p-cursor"
          >
            PDF <PDFIcon color="#ff0000" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
